import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import WebDesignServicesMain from "../components/WebDesignServicesMain"

const WebDesignPage = () => {
  const keyword = "Website Design Services"
  return (
    <Layout>
      <Seo
        noTitlePrefix={true}
        title={`${keyword} | Michigan Web Design | Snappy Web Design`}
        description={`Searching for ${keyword}? Snappy Web Design makes web design and development easy for businesses. Start reaching and engaging more leads.`}
        rating={true}
      />
      <WebDesignServicesMain keyword={keyword} />
    </Layout>
  )
}

export default WebDesignPage
